<template>
  <div v-if="loaded" class="container">
    <div class="d-flex justify-space-between">
      <v-btn class="my-auto" @click="goBack" outlined>
        {{ $vuetify.lang.t("$vuetify.base.back") }}
      </v-btn>
      <div class="d-flex justify-end">
        <v-switch
          v-model="hideVerified"
          :label="$vuetify.lang.t('$vuetify.packaging.hideVerified')"
        />
      </div>
    </div>
    <div class="text-center text-caption" v-if="filteredEntries">
      {{
        $vuetify.lang.t(
          "$vuetify.packaging.nPendingVerification",
          filteredEntries.length
        )
      }}
    </div>
    <v-sheet
      :elevation="2"
      class="ma-4 pa-4"
      v-for="entry in filteredEntries"
      :key="entry.id"
    >
      <div class="d-flex justify-space-between">
        <div v-if="!entry.uploadFiles || entry.uploadFiles.length < 1">
          <span class="text-caption">{{ entryIndex(entry) }}.</span>
          <v-checkbox @change="saveEntry(entry)" v-model="entry.verified" />
        </div>

        <div
          v-if="!entry.uploadFiles || entry.uploadFiles.length < 1"
          class="my-auto flex-grow-1 text-center"
        >
          <div class="font-weight-bold" v-if="entry.unitCode">
            <span> {{ entry.unitCode }} </span>
          </div>

          <div>
            {{ entry.netWeight | formatNumber }}
            {{ productInfo ? productInfo.measureWeight : "" }}
          </div>

          <div class="text-caption">
            <a
              class="link"
              @click="viewSelectedInventory(entry)"
              v-if="entry.inventoryId"
            >
              <div v-if="entry.documentNumber">
                Doc #: {{ entry.documentNumber }}
              </div>
              <div>Inventory ID: {{ entry.inventoryId }}</div>
            </a>
          </div>
        </div>

        <div class="my-auto">
          <image-upload
            class="mt-4 mx-2"
            v-model="entry.uploadFiles"
            :key="`${entry.id}-${addTime}`"
          />
          <div class="text-center">
            <div v-if="entry.files && entry.files.length > 0">
              <v-btn text @click="showImages(entry)"
                >{{ entry.files.length }} File</v-btn
              >
            </div>
            <div>
              <v-btn
                @click="uploadFiles(entry.id, entry.uploadFiles)"
                outlined
                small
                v-if="entry.uploadFiles && entry.uploadFiles.length > 0"
                >Upload</v-btn
              >
            </div>
          </div>
        </div>
      </div>
      <div class="text-caption mt-2 d-flex justify-space-between">
        <div>
          <span v-if="entry.verified">
            {{
              $vuetify.lang.t(
                "$vuetify.packaging.verifiedOn",
                $options.filters.formatDateTime(entry.verifiedOn)
              )
            }}
          </span>
        </div>
        <div>{{ entry.updateTime | formatDateTime }}</div>
      </div>
    </v-sheet>
    <v-dialog style="z-index: 1000" v-model="imgDialog">
      <v-card>
        <v-card-title class="justify-end">
          <div>
            <v-btn @click="imgDialog = null" icon
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-card-text>
          <v-carousel height="auto" v-if="images && images.length > 0">
            <v-carousel-item
              v-for="(img, i) in images"
              :key="i"
              reverse-transition="fade-transition"
              transition="fade-transition"
            >
              <v-img contain aspect-ratio="1.7" :src="img.src" />
              <div class="text-right text-caption ma-2">
                {{ img.time | formatDateTime }}
              </div>
            </v-carousel-item>
          </v-carousel>
          <div v-else class="text-subtitle-2 font-weight-light">
            No attachments found
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-bottom-sheet scrollable v-model="showInventoryDetails">
      <v-card>
        <v-card-title class="justify-center">
          <v-btn class="mt-6" text @click="showInventoryDetails = false">
            close
          </v-btn>
        </v-card-title>
        <v-card-text>
          <inventory-details
            :key="selectedInventory ? selectedInventory.id : 0"
            v-model="selectedInventory"
          />
          <div v-if="selectedInventoryUnit">
            <div class="text-center text-subtitle-1 my-2 font-weight-light">
              {{ this.$vuetify.lang.t("$vuetify.worksheet.selectedUnit") }}
            </div>
            <v-simple-table class="table">
              <tr>
                <td>Code</td>
                <td>{{ selectedInventoryUnit.code }}</td>
              </tr>
              <tr>
                <td>
                  # of {{ productInfo ? productInfo.measureUnit : "Unit" }}
                </td>
                <td>
                  {{ selectedInventoryUnit.unitShare }}
                  {{ productInfo ? productInfo.measureUnit : "" }}
                </td>
              </tr>
              <tr>
                <td>
                  Weight per
                  {{ productInfo ? productInfo.measureUnit : "Unit" }}
                </td>
                <td>
                  {{ selectedInventoryUnit.amountPerUnit | formatNumber }}
                  {{ selectedInventoryUnit.unitMeasure }}
                </td>
              </tr>
            </v-simple-table>
          </div>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { getProcessWorksheet } from "@/services/processWorksheet";
const ImageUpload = () => import("@/components/common/ImageUpload.vue");
const InventoryDetails = () =>
  import("@/components/inventory/InventoryDetails.vue");

export default {
  data: () => ({
    entries: [],
    imgDialog: false,
    selectedEntry: null,
    loaded: false,
    productInfo: null,
    inventories: [],
    showInventoryDetails: false,
    selectedInventory: null,
    selectedInventoryUnit: null,
    addTime: Date.now(),
    hideVerified: true,
  }),
  mounted() {
    this.id = this.$route.params.id;

    this.setInstance();
  },
  components: {
    ImageUpload,
    InventoryDetails,
  },
  computed: {
    images() {
      if (this.selectedEntry && this.selectedEntry.files.length > 0) {
        return this.selectedEntry.files.map((file) => {
          const src = `${process.env.VUE_APP_BASE_URL}/file/worksheet/${file.id}`;
          return { src, id: file.id, time: file.createdTime };
        });
      } else {
        return [];
      }
    },
    filteredEntries() {
      if (this.hideVerified) {
        return this.entries.filter(
          (entry) => entry.verified === undefined || !entry.verified
        );
      } else {
        return this.entries;
      }
    },
  },
  methods: {
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("categoryStore", ["fetchCategory"]),

    showImages(entry) {
      this.imgDialog = true;
      this.selectedEntry = entry;
    },
    setInstance() {
      getProcessWorksheet(this.id)
        .then(async (response) => {
          this.worksheet = response.data;
          this.entries = this.worksheet.entries;

          this.loadProductInfo();

          await this.loadInventoryDetails(this.entries);

          this.loaded = true;
        })
        .catch(() => {
          this.addErrorMessage("Error loading worksheet");
          this.loaded = true;
        });
    },
    async loadProductInfo() {
      if (this.worksheet.productId) {
        return await this.fetchCategory(this.worksheet.productId).then(
          (resp) => {
            this.productInfo = resp;

            return this.productInfo;
          }
        );
      } else {
        return null;
      }
    },
    viewSelectedInventory(worksheetEntry) {
      const inventoryId = worksheetEntry.inventoryId;
      const inventoryUnitId = worksheetEntry.inventoryUnitId;
      this.selectedInventory = this.getInventory(inventoryId);
      if (inventoryUnitId && this.selectedInventory.unitDetails) {
        this.selectedInventoryUnit = this.selectedInventory.unitDetails.find(
          (d) => d.id == inventoryUnitId
        );
      }
      this.showInventoryDetails = true;
    },
    async loadInventoryDetails(entries) {
      if (entries) {
        const inventoryIds = entries
          .filter((e) => e.inventoryId)
          .map((e) => e.inventoryId);
        if (inventoryIds && inventoryIds.length > 0) {
          const params = {
            ids: inventoryIds ? inventoryIds.join(",") : null,
            liteMode: false,
          };

          return await this.$axios.get("/inventory/by-ids", { params }).then((resp) => {
            this.inventories = resp.data;
            if (this.inventories && this.inventories.length > 0) {
              this.inventories.forEach((i) => {
                if (i.fields && i.fields.length > 0) {
                  const docNumberField = i.fields.find((f) =>
                    f.field.toLowerCase().includes("document no")
                  );
                  i.documentNumber = docNumberField
                    ? docNumberField.fieldValueString
                    : "";
                }
              });
            }

            //assign inventory id, document number, and unit code to entries
            this.assignEntryDocumentNumbers();
          });
        }
      }
    },
    getInventory(id) {
      if (this.inventories && this.inventories.length > 0) {
        return this.inventories.find((i) => i.id == id);
      } else {
        return {};
      }
    },
    getInventoryUnit(inventoryId, inventoryUnitId) {
      if (this.inventories && this.inventories.length > 0) {
        const inventory = this.inventories.find((i) => i.id == inventoryId);
        if (inventory.unitDetails) {
          return inventory.unitDetails.find((d) => d.id == inventoryUnitId);
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    assignEntryDocumentNumbers() {
      if (this.entries) {
        this.entries.forEach((e) => {
          const inventoryId = e.inventoryId;
          const inventoryUnitId = e.inventoryUnitId;

          const inventory = this.getInventory(inventoryId);
          const inventoryUnit = this.getInventoryUnit(
            inventoryId,
            inventoryUnitId
          );

          e.documentNumber = inventory.documentNumber;
          e.unitCode = inventoryUnit.code;
        });
      }
    },
    async uploadFiles(entryId, files) {
      if (files && files.length > 0) {
        var formData = new FormData();
        for (const f of files) {
          formData.append("files", f, f.name);
        }

        this.addMessage(this.$vuetify.lang.t("$vuetify.worksheet.uploading"));
        return await this.$axios
          .post(`/processWorksheetInput/upload/${entryId}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((resp) => {
            const savedFiles = resp.data;
            const entry = this.worksheet.entries.find((e) => e.id == entryId);
            if (!entry.files) {
              entry.files = [];
            }
            savedFiles.forEach((f) => {
              entry.files.push({
                id: f.id,
                name: f.originalFileName,
                size: f.size,
              });
            });

            entry.uploadFiles = null;

            this.addTime = Date.now();
            this.addSuccessMessage(
              this.$vuetify.lang.t(
                "$vuetify.worksheet.uploadedXFiles",
                files.length
              )
            );
          });
      }
    },
    saveEntry(entry) {
      const params = {};
      if (!entry.verified) {
        params.unVerify = true;
      }
      this.$axios
        .put("/processWorksheetInput/verify-entry/" + entry.id, null, {
          params,
        })
        .then(() => {
          entry.verifiedOn = Date.now();
          this.addSuccessMessage(`${entry.unitCode} ${this.$vuetify.lang.t("$vuetify.message.confirmed")}`)
        });
    },
    entryIndex(entry) {
      if (this.worksheet && this.worksheet.entries) {
        const index = this.worksheet.entries.findIndex((e) => e.id == entry.id);
        if (index >= 0) {
          return index + 1;
        } else {
          return 0;
        }
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
a.link {
  color: green;
}
</style>
